var render = function render(){var _vm=this,_c=_vm._self._c;return _c('article',{staticClass:"product-card",class:{
    'product-card_tall' : _vm.tall,
    'product-card_min' : _vm.minified,
    'product-card-favorites': _vm.pageType === 'favorites',
  }},[(!_vm.minified)?_c('div',{staticClass:"product-card__header"},[_c('ProductCardBadges',{attrs:{"badges":_vm.item.badges}}),_vm._v(" "),_c('div',{staticClass:"product-card__actions"},[(_vm.pageType === 'catalog')?_c('button',{staticClass:"product-card__favorite",class:{
          'product-card__favorite--active': _vm.productInFavorites(_vm.item.productId)
        },attrs:{"aria-label":"В избранное","data-test":"product-card-add-to-favorite"},on:{"click":_vm.toggleToFavoritesItem}},[_c('SvgIcon',{staticClass:"product-card__favorite-ico",attrs:{"name":_vm.productInFavorites(_vm.item.productId) ? 'cat-fav-filled' : 'cat-fav',"width":"24","height":"24"}})],1):_vm._e(),_vm._v(" "),(_vm.pageType === 'promo')?_c('button',{staticClass:"product-card__remove-from-promo",attrs:{"aria-label":"Удалить с промостраницы","data-test":"product-card-remove-from-promo"},on:{"click":_vm.removeFromPromo}},[_c('svg-icon',{attrs:{"name":"close","width":"16","height":"16"}})],1):_vm._e(),_vm._v(" "),(_vm.pageType === 'catalog')?_c('Button',{staticClass:"product-card__btn no-padding-btn product-card__cart",class:{
          'product-card__cart--active': _vm.cardBtn.inCart
        },attrs:{"theme":_vm.cardBtn.theme,"disabled":_vm.cardBtn.disabled,"round":!_vm.cardBtn.name},on:{"click":_vm.cardBtn.onClick}},[(_vm.cardBtn.icon)?_c('SvgIcon',{staticClass:"product-card__favorite-ico",attrs:{"name":_vm.cardBtn.icon,"width":"24","height":"24"}}):_vm._e(),_vm._v("\n        "+_vm._s(_vm.cardBtn.name)+"\n      ")],1):_vm._e()],1)],1):_vm._e(),_vm._v(" "),_c('nuxt-link',{staticClass:"product-card__link",attrs:{"to":{
      name: 'catalog.category.product',
      params: {
        category: _vm.categoryLink,
        product: _vm.item.code,
      }
    }}},[_c('div',{staticClass:"product-card__img-wrapper"},[(!_vm.productPosters.length)?_c('CustomImage',{staticClass:"product-card__img",attrs:{"image-id":_vm.item.image.id,"alt":_vm.item.image.caption || _vm.item.name,"width":_vm.imageSizes[0],"height":_vm.imageSizes[1],"aspect-ratio":_vm.tall ? '133.24%' : '100%',"loading":_vm.loading}}):_c('ProductCardPosters',{attrs:{"id":`${_vm.categoryLink}-${_vm.item.code}`,"posters":_vm.productPosters,"sizes":_vm.imageSizes,"aspect-ratio":_vm.tall ? '133.24%' : '100%',"loading":_vm.loading}})],1),_vm._v(" "),_c('div',{staticClass:"product-card__brand"},[_vm._v("\n      "+_vm._s(_vm.item.brandName)+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"product-card__body"},[_c('h3',{staticClass:"product-card__name product-card__name_cutted font font_center"},[_vm._v("\n        "+_vm._s(_vm.item.name)+"\n      ")])]),_vm._v(" "),_c('div',{staticClass:"product-card__info"},[_c('div',{staticClass:"product-card__price font",class:{
          'font_title-l': _vm.pageType === 'favorites',
          'font_m': _vm.pageType !== 'favorites',
        }},[(_vm.item.price)?[_c('div',{staticClass:"product-card__price-wrapper"},[(_vm.item.price.value && _vm.item.price.value.from)?[(_vm.item.oldPrice && _vm.item.oldPrice.value && _vm.item.oldPrice.value.from)?_c('div',{staticClass:"product-card__price-old font font_grey",class:{
                  'font_xs': _vm.mqIsMobile,
                  'font_m': !_vm.mqIsMobile,
                }},[_vm._v("\n                от "+_vm._s(_vm.getCurrency(_vm.item.oldPrice.value.from))+"\n              ")]):_vm._e(),_vm._v(" "),_c('div',{class:{
                  'font_medium font_xs': _vm.mqIsMobile,
                  'font_bold font_m': !_vm.mqIsMobile,
                  'product-card__price_sale': _vm.item.oldPrice && _vm.item.oldPrice.value.from
                }},[_vm._v("\n                от "+_vm._s(_vm.getCurrency(_vm.item.price.value.from))+"\n              ")])]:(_vm.item.price.value)?[(_vm.item.oldPrice && _vm.item.oldPrice.value)?_c('div',{staticClass:"product-card__price-old font font_grey",class:{
                  'font_xs': _vm.mqIsMobile,
                  'font_m': !_vm.mqIsMobile,
                }},[_vm._v("\n                "+_vm._s(_vm.getCurrency(_vm.item.oldPrice.value))+"\n              ")]):_vm._e(),_vm._v(" "),_c('div',{class:{
                  'font_medium font_xs': _vm.mqIsMobile,
                  'font_medium font_m': !_vm.mqIsMobile,
                  'product-card__price_sale': _vm.item.oldPrice && _vm.item.oldPrice.value
                }},[_vm._v("\n                "+_vm._s(_vm.getCurrency(_vm.item.price.value))+"\n              ")])]:_c('div',{class:{
                'font_xs font_medium': _vm.mqIsMobile,
                'font_m font_medium': !_vm.mqIsMobile,
              }},[_vm._v("\n              "+_vm._s(_vm.getCurrency(_vm.item.price))+"\n            ")])],2)]:_vm._e()],2)])]),_vm._v(" "),_c('div',{staticClass:"product-card-add-to-cart-favorites-container"},[(_vm.pageType === 'favorites')?_c('Button',{staticClass:"product-card-add-to-cart-favorites",attrs:{"aria-label":"Удалить из избранного","data-test":"product-card-remove-from-favorites"},on:{"click":_vm.toggleToFavoritesItem}},[_c('SvgIcon',{attrs:{"name":"bin","width":"20","height":"20"}})],1):_vm._e(),_vm._v(" "),(_vm.pageType === 'favorites' && !_vm.productInCart(_vm.item.productId))?_c('Button',{staticClass:"product-card-add-to-cart-favorites",class:{
        'product-card__cart--active': _vm.cardBtn.inCart
      },on:{"click":_vm.onAddCartButtonClick}},[(_vm.cardBtn.icon)?_c('svg-icon',{attrs:{"name":_vm.cardBtn.icon,"width":"32","height":"32"}}):_vm._e()],1):_vm._e()],1),_vm._v(" "),(_vm.pageType === 'favorites' && _vm.productInCart(_vm.item.productId))?_c('Button',{staticClass:"product-card-add-to-cart-favorites product-card-in-cart",on:{"click":_vm.handleGoToCart}},[_c('svg-icon',{staticClass:"add-to-cart-icon",attrs:{"name":"site-header/cart-small-white","width":"16","height":"16"}}),_vm._v(" В корзине\n  ")],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }