<template>
  <div ref="loader" class="lazy-loader">
    <slot />

    <LoaderDots
      v-show="loading"
      class="lazy-loader__loading"
    />
  </div>
</template>

<script>
import LoaderDots from '@/components/elements/LoaderDots';

export default {
  components: {
    LoaderDots,
  },
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    nextPage: {
      type: Boolean,
      required: true,
    },
  },
  mounted() {
    this.$nextTick(function() {
        window.addEventListener('scroll', this.onScroll);
        this.onScroll();
    });
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll);
  },
  methods: {
    onScroll() {
      const element = this.$refs.loader;

      if (element && !this.loading && this.nextPage) {
        const ordersBottom = element.getBoundingClientRect().bottom;
        const innerHeight = window.innerHeight;

        if ((ordersBottom - innerHeight) < 50) {
          this.$emit('update');
        }
      }
    },
  },
}
</script>
